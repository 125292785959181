<template>
  <v-container id="chat" fluid tag="section">
    <div class="message" v-show="session && Object.keys(session).length > 0" ref="mianscroll">
      <div class="message-group" v-for="(item, index) in messages" :key="index"
        :class="item.mstype !== 'question' ? 'robot_answer_bg' : ''">
        <div class="message-content">
          <v-icon color="#fff" size="30" class="mr-6 user">{{
            item.mstype == "question"
            ? "mdi-account-outline"
            : "mdi-robot-excited-outline"
          }}</v-icon>
          <div class="answer_result" @mouseenter="getCopy(item)">
            <codeBox :msinfo="item.msinfo" :copyContent="copyContent" />
          </div>
        </div>
      </div>
    </div>
    <div class="search" v-show="Object.keys(session).length">
      <!-- <div class="pause" v-if="isChating && messages[messages.length - 1].mstype !== 'question'">
        <v-btn width="100%" class="mb-2" :loading="pauseLoading" @click="handelPause">
          <v-icon class="mr-2">mdi-stop-circle-outline</v-icon><span>停止生成</span>
        </v-btn>
      </div> -->
      <div class="search-input">
        <textarea id="textarea" v-model="content" placeholder="输入一条消息..."
          onkeydown="if(event.keyCode==13)return false;" />
        <v-btn density="compact" small :loading="sendLoading">
          <v-icon @click="sendMsg">mdi-send-variant-outline</v-icon>
        </v-btn>
      </div>
    </div>
    <v-btn rounded class="move-down" v-if="showTobottom && messages.length > 5"
      @click="bottomScrollClick"><v-icon>mdi-chevron-down</v-icon></v-btn>
  </v-container>
</template>

<script>
import Cookies from "js-cookie";
import { mapState } from "vuex";
import { sendData, closewebsocket, reconnect } from "@/plugins/socket";
import { chat } from "@/api";
import codeBox from "@/views/dashboard/component/codeBox";
export default {
  name: "chat",
  data() {
    return {
      content: "",
      contentCopy: "",
      sendLoading: false,
      pauseLoading: false,
      allMessages: [],
      count: 0,
      time: 120,
      copyContent: "",
      showTobottom: false,
      timer: null,
      sendMsgTimer: null,
      inputTokens: 0,
      responseTokens: 0
    };
  },
  components: { codeBox },
  computed: {
    ...mapState(["session", "record", "socketMsg", "isChating", "wsTimeoutClose"]),
    messages() {
      if (this.session.id) {
        let v = this.record.find((v) => {
          return this.session.id === v.gtId;
        });
        console.log()
        return v.qaList;
      } else {
        return [];
      }
    },
  },
  watch: {
    "session.id": {
      handler(newV, oldV) {
        this.scrollToBottom();
      },
      deep: true,
    },
    socketMsg(string) {
      this.renderAnswer(string);
    },
  },
  methods: {
    //发送消息
    sendMsg() {
      let _this = this;
      if (_this.sendLoading) return;
      if (!_this.content) {
        alert("内容不能为空");
        return;
      }
      if (_this.wsTimeoutClose) {
        _this.sendLoading = true;
        reconnect()
        _this.sendMsgTimer = setTimeout(() => {
          _this.sendMsgP2()
        }, 1500);
      } else {
        _this.sendMsgP2()
      }
    },
    async sendMsgP2() {
      let _this = this
      if (_this.sendMsgTimer) clearTimeout(_this.sendMsgTimer)
      _this.messages.push({
        mstype: "question",
        msinfo: _this.content,
      });
      _this.sendLoading = true;
      //是否回复中
      _this.$store.commit("SET_ISCHATING", true);
      _this.contentCopy = _this.content;
      _this.content = "";
      _this.restTextarea();
      let msgArr = _this.messages.map((v) => {
        return {
          role: v.mstype == "question" ? "user" : "assistant",
          content: v.msinfo,
        };
      });
      let params = JSON.stringify({
        messages: msgArr,
        gtId: this.session.id,
        uid: Number(Cookies.get("gpt_uid")),
      });
      sendData(params);
      _this.restTextarea();
      _this.connecTime()
    },
    // 停止生成
    handelPause() {
      sendData("pause");
      this.count = 0;
      this.$store.commit("SET_ISCHATING", false);
      this.sendLoading = false;
      this._saveRecord();
    },
    //保存聊天记录
    async _saveRecord() {
      await chat({
        guId: Number(Cookies.get("gpt_uid")),
        gtId: this.session.id,
        question: this.contentCopy,
        answer: this.messages[this.messages.length - 1].msinfo,
        inputTokens: this.inputTokens,
        responseTokens: this.responseTokens
      });
    },
    // 渲染消息
    async renderAnswer(string) {
      if (string.includes("error")) {
        let msg = JSON.parse(string).error.message;
        this.$store.commit("SET_ISCHATING", false);
        this.sendLoading = false;
        alert(msg);
        return;
      }

      if (string.split(':')[0] === 'inputTokens') {
        this.inputTokens = string.split(':')[1] || 0
      } else if (string.split(':')[0] === 'responseTokens') {
        this.responseTokens = string.split(':')[1] || 0
        this._saveRecord();
      } else {
        if (string !== "[DONE]" && this.isChating) {
          if (this.count == 0) {
            let showMessage = {
              mstype: "answer",
              msinfo: string,
            };
            this.messages.push(showMessage);
          } else {
            this.messages[this.messages.length - 1].msinfo =
              this.messages[this.messages.length - 1].msinfo + string;
            this.scrollToBottom();
          }
          this.count++;
        } else {
          this.count = 0;
          this.$store.commit("SET_ISCHATING", false);
          this.sendLoading = false;
        }
      }
    },
    requestTimeOut() {
      this.timer = setInterval(() => {
        if (this.time <= 0) {
          if (this.sendLoading) alert("请求超时");
          clearInterval(this.timer);
          this.time = 120;
          this.$store.commit("SET_ISCHATING", false);
          this.sendLoading = false;
        } else {
          this.time--;
        }
      }, 1000);
    },
    //输入框高度复原
    restTextarea() {
      this.$nextTick(() => {
        document.getElementById("textarea").style.height = 45 + "px";
      });
    },
    // 新消息推送 -- 滚动到底部
    scrollToBottom() {
      this.$nextTick(() => {
        var scrollEl = this.$refs.mianscroll;
        if (!this.showTobottom) {
          scrollEl.scrollTop = scrollEl.scrollHeight;
        }
      });
    },
    //点击按钮 -- 滑动到底部
    bottomScrollClick() {
      this.$nextTick(() => {
        let scrollEl = this.$refs.mianscroll;
        scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: "smooth" });
      });
    },
    getCopy(item) {
      this.copyContent = item.msinfo;
    },
    connecTime() {
      console.log('连接时间开始计算~~')
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        if (!this.isChating) {
          closewebsocket()
          this.$store.commit('SET_WS_TIMEOUTCLOSE', true)
          console.log('5分钟未操作，ws连接断开~~')
        }
      }, 300000);

    }
  },
  mounted() {
    // 回车
    let self = this;
    document.onkeydown = function (e) {
      let ev = document.all ? window.event : e;
      if (ev.keyCode === 13) {
        self.sendMsg();
      }
    };
    //文本框自动高度
    var textarea = document.querySelector("textarea");
    textarea.addEventListener("input", (e) => {
      textarea.style.height = "auto";
      textarea.style.height = e.target.scrollHeight + "px";
    });
    //滚动到底部按钮
    let window = this.$refs.mianscroll;
    window.addEventListener("scroll", () => {
      if (window.scrollHeight - window.scrollTop !== window.clientHeight) {
        this.showTobottom = true;
      } else {
        this.showTobottom = false;
      }
    });
    this.connecTime()
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    if (this.sendMsgTimer) {
      clearTimeout(this.sendMsgTimer)
      this.sendMsgTimer = null
    }
    closewebsocket()
  }
};
</script>
<style lang="scss" scoped>
#chat {
  padding: 0;

  .message {
    width: 100%;
    height: calc(100vh - 10rem);
    overflow-y: auto;

    .robot_answer_bg {
      background-color: rgb(68 70 84 / var(--tw-bg-opacity)) !important;
    }

    .message-group {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 1.5rem;

      .message-content {
        width: 48rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 1.8rem 0;

        .answer_result {
          font-size: 1.1rem;
          color: #d1d5db !important;
          white-space: pre-line;
          width: 100%;
        }
      }
    }
  }

  .search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
    position: relative;

    .pause {
      width: 10rem;
      position: absolute;
      top: -4rem;

      ::v-deep .theme--dark.v-navigation-drawer {
        --tw-bg-opacity: 1 !important;
        background-color: rgb(32 33 35 / var(--tw-bg-opacity)) !important;
      }

      .theme--light.v-btn.v-btn--has-bg {
        border: 1px solid hsla(0, 0%, 100%, 0.2) !important;
      }

      .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: #343541 !important;
      }
    }

    .search-input {
      margin-top: 2rem;
      width: 48rem;
      position: relative;

      textarea:focus {
        border: 2px solid #fff !important;
        transition: all 0.5s !important;
      }

      textarea::-webkit-input-placeholder {
        color: #9ca3af;
      }

      textarea {
        outline: none;
        width: 100%;
        min-height: 35px;
        max-height: 300px;
        background-color: transparent;
        color: #fff;
        padding-left: 1rem;
        box-sizing: border-box;
        border: 1.5px solid rgba(17, 24, 39, 0.5);
        border-radius: 6px;
        resize: none;
        font-size: 1.1rem;
        margin-bottom: 2rem;
        overflow: auto;
        max-height: 300px;
      }

      .v-btn {
        position: absolute;
        right: -15px;
        top: 10px;
        font-size: 1.6rem;
        color: #9ca3af;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }

  .move-down {
    background-color: #329ef4;
    position: fixed;
    right: 0;
    bottom: 10rem;
    border-radius: 50%;
    min-width: 0;
    width: 3rem;
    padding: 0 0 !important;
  }

  @media only screen and (max-width: 768px) {
    .message-group {
      padding: 0 0.8rem !important;
      box-sizing: border-box;
    }

    .message-content {
      flex-direction: column;
      padding-top: 0.5rem !important;
      width: 23rem !important;

      .user {
        margin-bottom: 0.8rem;
      }
    }
  }
}
</style>
