<template>
    <div class="codeBox">
        <div v-highlight v-html="msg" style="word-break:break-all"></div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { parseAndHighlightCode, parseCode } from "@/plugins/utils";
import Clipboard from 'clipboard'

export default {
    props: {
        msinfo: {
            type: String,
            default: "",
        },
        copyContent: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            id: 0
        };
    },
    computed: {
        ...mapState(["socketMsg"]),
        msg() {
            return parseAndHighlightCode(this.msinfo);
        },
    },
    mounted() {
        this.copy()
    },
    watch: {},
    methods: {
        copy() {
            let clipboard = new Clipboard('.copy-code', {
                text: () => {
                    return parseCode(this.copyContent)
                }
            })
            clipboard.on('success', () => {
            })
            clipboard.on('error', () => {
            })
        }
    },
};
</script>
<style lang="scss">
.theme--light.v-application code {
    background: rgb(40, 44, 52);
    color: currentColor;
    border-radius: 0;
    font-size: 1.1rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

.code-title {
    padding: 0.7rem 1rem;
    display: flex;
    justify-content: space-between;
    color: #fff;
    background: #22232A;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    font-size: .7rem;

    .copy-code {
        cursor: pointer;
    }
}
</style>
